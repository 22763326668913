<template>
  <div>

    <div style="display: flex;flex-direction: row;justify-content: space-between;">
      <el-select v-model="value" placeholder="请选择" style="margin: 8px" @change="getWeekNums">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <p style="font-size: 2.2em;font-weight: bold;margin-top: 10px">{{ title }}</p>
      <div></div>
    </div>

    <div style="display: flex;flex-direction: row;justify-content: flex-start;align-items: center">
      <el-button v-on:click="openAddWeekForm" type="primary" style="max-height: 50px;margin: 8px">新建周报表</el-button>
      <el-button v-on:click="openDeleteWeekForm" type="primary" style="max-height: 50px;margin: 8px">删除周报表</el-button>
      <el-select v-model="showWeekNum" placeholder="请选择要查看的周报表" @change="selectWeekForm">
        <el-option
          v-for="item in weekNums"
          :key="item"
          :label="item"
          :value="item"
          >
        </el-option>
      </el-select>
      <el-button @click="exportTable" type="primary" style="max-height: 50px;margin: 8px">导出报表</el-button>
    </div>

    <el-table
      id="myTable"
      :data="weekForm"
      border
      style="width: 100%">
      <el-table-column
        fixed
        prop="centerStation"
        label="中心站"
        width="180">
      </el-table-column>
      <el-table-column
        fixed
        prop="machineName"
        label="机组"
        width="180">
      </el-table-column>
      <el-table-column
        prop="area"
        width="120"
        label="开阀面积">
      </el-table-column>
      <el-table-column
        prop="stay"
        width="100"
        label="入住率">
      </el-table-column>
      <el-table-column
        width="100"
        prop="thisWeekRatio"
        label="本周比例">
      </el-table-column>
      <el-table-column
        width="120"
        prop="thisWeekPlan"
        label="本周计划指标">
      </el-table-column>
      <el-table-column
        width="120"
        prop="lastWeekExpend"
        label="上周实耗量">
      </el-table-column>

      <el-table-column
        width="120"
        prop="thisWeekExpend"
        label="本周实耗量">
      </el-table-column>
      <el-table-column
        width="120"
        prop="thisWeekUnit"
        label="本周单耗">
      </el-table-column>
      <el-table-column
        width="120"
        prop="lastYearThisWeekUnit"
        label="去年本周单耗">
      </el-table-column>
      <el-table-column
        width="120"
        prop="sumPlan"
        label="累计计划指标">
      </el-table-column>
      <el-table-column
        width="120"
        prop="sumExpend"
        label="累计实耗量">
      </el-table-column>
      <el-table-column
        width="120"
        prop="sumUnit"
        label="累计单耗">
      </el-table-column>

      <el-table-column
        width="120"
        prop="thisWeekResidue"
        label="本周结余">
      </el-table-column>

      <el-table-column
        width="120"
        prop="sumResidue"
        label="累计结余">
      </el-table-column>

      <el-table-column
        width="120"
        prop="yearPlan"
        label="年度给定">
      </el-table-column>

    </el-table>

    <el-dialog title="新增周报表对象" :visible.sync="dialogFormVisible">
      <el-form :model="weekFormAddDTO">
        <el-form-item label="第几周:" >
          <el-input v-model="weekFormAddDTO.weekNum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="日期选择" >
          <el-date-picker
            v-model="weekFormAddDTO.startTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="起始日期">
          </el-date-picker>
          <el-date-picker
            style="margin-left: 20px"
            v-model="weekFormAddDTO.stopTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="createWeekForm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除周报表" :visible.sync="dialogFormVisibledelete">
      <el-form :model="deleteWeekNum">
        <el-form-item label="删除第几周:" >
          <el-select v-model="deleteWeekNum" placeholder="请选择">
            <el-option
              v-for="item in weekNums"
              :key="item"
              :label="item"
              :value="item"
              >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibledelete = false">取 消</el-button>
        <el-button type="primary" @click="deleteWeekForm">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import clientUtils from "@/js/clientUtils";
import {exportExcelByTableId} from "@/js/excel";

export default {
  name: "WeekForm",
  data() {
    return {
      title: "",
      options: [{
        value: '水',
        label: '水'
      }, {
        value: '电',
        label: '电'
      }, {
        value: '热',
        label: '热'
      }],
      value: '',
      dialogTableVisible: false,
      dialogFormVisible: false,
      dialogFormVisibledelete: false,
      weekFormAddDTO: {
        startTime: "",
        stopTime: "",
        weekNum: ""
      },
      weekNums: [],
      deleteWeekNum: "",
      weekForm: [],
      showWeekNum: "",
      showWeek: false,

    }
  },
  methods: {
    initTitle: function () {    //拼接title的方法，在挂载期调用
      this.userInfo = clientUtils.getLocalUserInfo();
      let myDate = new Date();
      let year = myDate.getFullYear()
      let month = myDate.getMonth()+1

      if (month>=11){
        this.title = year + "~" + (year + 1) + "年度采暖期" + this.userInfo.allowCompanys + "服务部换热站" + sessionStorage.getItem("dayFromType") + "耗统计表"
      }else {
        this.title = year-1 + "~" + (year) + "年度采暖期" + this.userInfo.allowCompanys + "服务部换热站" + sessionStorage.getItem("dayFromType") + "耗统计表"
      }
    },

    //新建周报表对象
    createWeekForm: function () {
      const that = this;
      let updatejosn = JSON.stringify(this.weekFormAddDTO);
      clientUtils.post("/system/weekPlan/save?type=" + that.value, updatejosn, function (res) {
        if (res.data != null) {
          // 编辑用户信息成功
          that.back();
          that.getWeekNums()
          that.$message({
            message: '数据更新成功',
            type: 'success'
          });
        } else {
          clientUtils.unknownErrorAlert(that, res.data.msg)
        }
      })

    },

    back: function () {
      this.dialogFormVisible = false
    },

    openAddWeekForm: function () {
      this.dialogFormVisible = true
    },

    openDeleteWeekForm: function () {
      this.getWeekNums()
      this.dialogFormVisibledelete = true
    },

    //获取周报表一共有几周
    getWeekNums: function () {
      const that = this
      clientUtils.get("/system/weekPlan/selectWeekNum?type=" + this.value, function (res) {
        if (res.data != null) {
          console.log("数据：" + res.data.data)
          that.weekNums = res.data.data
          that.showWeekNum = that.weekNums[0]
          that.selectWeekForm()

        }
      })
    },

    //删除周报表对象
    deleteWeekForm: function () {
      this.getWeekNums
      const that = this
      clientUtils.get("/system/weekPlan/deleteWeekForm?weekNum=" + that.deleteWeekNum, function (res) {
        if (res.data != null) {
          console.log("数据：" + res.data)
          that.getWeekNums()
          that.deleteWeekNum = "";
          that.dialogFormVisibledelete = false
        }
      })
    },

    //查询周报表对象
    selectWeekForm: function () {
      const that = this
      clientUtils.get("/system/weekPlan/selectWeekForm?weekNum=" + this.showWeekNum + "&type=" + that.value, function (res) {
        if (res.data != null) {
          console.log("数据：" + res.data.data)
          that.weekForm = res.data.data
        }
      })
    },
    exportTable: function () {
      exportExcelByTableId("myTable", "周报表")
    }
  },
  mounted() {
    this.getWeekNums()

  },
  created() {
    this.value = "水"
    sessionStorage.setItem("dayFromType", this.value)
    this.initTitle()
  }
}
</script>

<style scoped>

</style>
